<!-- BREADCRUMB -->
<div class="breadcrumb-container">
  <p-breadcrumb
    class="max-w-full"
    [model]="verifiedDocs.breadcrumbPath"
    [home]="verifiedDocs.breadcrumbIcon"
  >
    <ng-template pTemplate="item" let-item>
      <span class="breadcrumb-path">{{ item.label }}</span>
    </ng-template>
  </p-breadcrumb>
  <p-button
    label="Search with AI"
    icon="pi pi-search"
    class="search-button"
    size="small"
    (mousedown)="showDialog()"
  ></p-button>
</div>

<!-- DIALOG ASK MISTRAL -->
<p-dialog
  header="Ask AI"
  [modal]="true"
  [(visible)]="askMistral.isDisplayed"
  [style]="{ width: '66%' }"
>
  <p-inputGroup class="search-ai">
    <p-inputGroupAddon>
      <i class="pi pi-search"></i>
    </p-inputGroupAddon>
    <input
      pInputText
      placeholder="Search with Mistral"
      [(ngModel)]="askMistral.askMistralQuery"
      (keyup.enter)="getMistralAnswer()"
    />
  </p-inputGroup>
  @if (askMistral.isLoading) {
    <div class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
  } @else {
    <div class="ask-ai-answer">
      <span [innerHTML]="askMistral.askMistralAnswer"></span>
    </div>
    <!-- DO NOT REMOVE 'PRESENCEDOC_APP_EXTENSION' AND 'id="{{file.id}}' THEY ARE USED FOR THE EXTENSION -->
    <div class="ask-ai-files">
      @for (
        doc of askMistral.askMistralSources;
        track doc;
        let index = $index
      ) {
        <div class="ask-ai-file-name">
          Source :
          <a href="{{ getDocUrl(doc) }}" target="_blank">
            <p-tag
              value="{{ doc?.name }}"
              class="clickable-p-tag PRESENCEDOC_APP_EXTENSION"
              id="{{ doc?.google_file_id }}"
            ></p-tag>
          </a>
        </div>
      }
    </div>
  }
</p-dialog>

<!-- FILTERS -->
<div class="filters-row">
  <p-multiSelect
    [options]="filters.tags.values"
    (onChange)="updateFilter('tags', $event)"
    (onClear)="clearFilter('tags')"
    placeholder="Select tags"
    optionLabel="label"
    display="chip"
    class="multi-select"
  />
  <p-multiSelect
    [options]="filters.contributors.values"
    (onChange)="updateFilter('contributors', $event)"
    (onClear)="clearFilter('contributors')"
    placeholder="Select contributors"
    optionLabel="email"
    display="chip"
    class="multi-select"
  >
    <ng-template let-creator pTemplate="item">
      <div class="multi-select-row">
        <p-avatar
          image="{{ creator.photo_uri ?? DEFAULT_AVATAR_URI }}"
          styleClass="mr-2"
          size="normal"
          shape="circle"
          class="creator-avatar"
        ></p-avatar>
        <div>{{ creator.email }}</div>
      </div>
    </ng-template>
  </p-multiSelect>
  <p-multiSelect
    [options]="filters.labels.values"
    (onChange)="updateFilter('labels', $event)"
    (onClear)="clearFilter('labels')"
    placeholder="Select labels"
    optionLabel="type"
    display="chip"
    class="multi-select"
  />
  <p-multiSelect
    [options]="filters.formats.values"
    (onChange)="updateFilter('formats', $event)"
    (onClear)="clearFilter('formats')"
    placeholder="Select formats"
    optionLabel="shortMimetype"
    display="chip"
    class="multi-select"
  >
    <ng-template let-format pTemplate="item">
      <div class="multi-select-row">
        <img
          src="{{ format.iconLink }}"
          alt="avatar"
          class="result-icon"
          class="file-icon"
        />
        <div>{{ format.shortMimetype }}</div>
      </div>
    </ng-template>
  </p-multiSelect>
</div>

<!-- HORIZONTAL LOADER -->
<div class="body-content">
  @if (verifiedDocs.areFilesLoading) {
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }" />
  } @else {
    <div [style]="{ height: '6px' }"></div>
  }
</div>

<!-- TABLE -->
<div class="scroll-y {{ verifiedDocs.dynamicClass }}">
  <app-ng-table [documents]="verifiedDocs.displayedDocuments"></app-ng-table>
</div>

@if (verifiedDocs.isFooterDisplayed) {
  <div class="body-content-footer">
    <app-footer
      [selectedFiles]="selectedFiles"
      [properties]="true"
    ></app-footer>
  </div>
}
