import { GoogleMimeTypes, KnownMimesToShorten } from '../types/constants';
import { File, PrimeFileNode } from '../types/documentTypes';
import mime from 'mime';
import { PeopleInfo } from '../types/peopleTypes';
import { ToastService } from 'app/services/toast/toast.service';

export function isGoogleContainer(node: PrimeFileNode): boolean {
  return [GoogleMimeTypes.drive, GoogleMimeTypes.folder].includes(
    node.mime_type as GoogleMimeTypes,
  );
}

export function shortMimeType(mimeType: string): string {
  let short: string | null = KnownMimesToShorten[mimeType];

  if (short) return short;

  short = mime.getExtension(mimeType);

  if (short) return short as string;

  return mimeType;
}

export function pushIfNotFound<T>(
  container: T[],
  entity: T,
  predicate: (value: T, index: number, obj: T[]) => boolean,
) {
  if (container.findIndex(predicate) === -1) container.push(entity);
}

export function getFilePrimaryContributor(file: File): PeopleInfo | undefined {
  return file.datamart?.created_by?.email?.includes('@')
    ? file.datamart.created_by
    : file.last_modified_by;
}

export function getParentDrive(node: PrimeFileNode): PrimeFileNode | undefined {
  if (node.mime_type === GoogleMimeTypes.drive) return node;

  if (!node.parent) return undefined;

  return getParentDrive(node.parent);
}

export function getFileContributors(
  file: File,
): { contribs: PeopleInfo[]; count: number; names: string } | undefined {
  const contributors = file.datamart?.contributors;
  const MAX_VISIBLE_CONTRIBUTORS = 3;

  if (
    file.datamart?.created_by &&
    !contributors?.some(
      (contributor) => contributor.email === file.datamart?.created_by?.email,
    )
  ) {
    contributors?.unshift(file.datamart.created_by);
  }

  if (contributors) {
    const firstThreeContributors: PeopleInfo[] = contributors.slice(
      0,
      MAX_VISIBLE_CONTRIBUTORS,
    );
    const remainingContributorsCount: number =
      contributors.length > MAX_VISIBLE_CONTRIBUTORS
        ? contributors.length - MAX_VISIBLE_CONTRIBUTORS
        : 0;
    const remainingContributorsNames: string = contributors
      .slice(MAX_VISIBLE_CONTRIBUTORS)
      .map((contributor) => contributor.name)
      .join(' and ');

    return {
      contribs: firstThreeContributors,
      count: remainingContributorsCount,
      names: remainingContributorsNames,
    };
  }

  return undefined;
}

export function redirectTo(link: string): void {
  window.open(link, '_blank');
}

// clipboard.utils.ts
export function copyToClipboard(text: string, toastService: ToastService): void {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);

  if (toastService) {
    toastService.success({
      detail: 'Password copied to clipboard!',
    });
  }
}
