import { Component, OnInit } from '@angular/core';
import { TranslationsService } from './services/translations.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  readonly CHILDREN_ROUTES: string[] = [];
  readonly NO_SIDEBAR_ROUTES = [
    (url: string) => url.includes('/login'),
    (url: string) => url.includes('/side-panel'),
    (url: string) => url.includes('/404'),
    (url: string) => url.includes('/onboarding'),
    (url: string) => url.includes('/onboarding/start'),
    (url: string) => url === '/',
  ];
  title = 'federer';
  authorizedRoute: boolean = false;
  currentUrl: string = '';

  constructor(
    private translationsService: TranslationsService,
    private router: Router,
  ) {
    this.translationsService.init();
    // Fill the Children Routes
    this.router.config.forEach((route) => {
      if (route?.children) {
        this.CHILDREN_ROUTES.push(route.path as string + '/');
      }
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const currentUrl: string = (event as NavigationEnd).url;
        this.authorizedRoute = !this.NO_SIDEBAR_ROUTES.some((fn) =>
          fn(currentUrl),
        );
        // Check if the route is not part of the path routes
        const path: string = currentUrl.substring(1);

        if (this.router.config.filter(
          (route) => route.path === path ||
          this.CHILDREN_ROUTES.filter(
            (child) => path.startsWith(child),
          ).length > 0,
        )
        .length === 0) {
          this.authorizedRoute = false;
        }
      });
  }
}
