import {
  DialogsKey,
  Heartbeat,
  SidePanel,
  Status,
  Worklow,
} from './side-panel.types';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileService } from 'app/services/file/file.service';
import { LikeService } from 'app/services/like/like.service';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import { RatingService } from 'app/services/rating/rating.service';
import { TagService } from 'app/services/tag/tag.service';
import { PeopleInfo } from 'app/types/peopleTypes';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { EventManager } from 'app/services/events/events.service';
import { AuthService } from 'app/services/auth/auth.service';
import { CONFIDENTIALITIES, DOC_TYPES, LANGUAGES } from 'app/types/constants';
import { ToastService } from '../../services/toast/toast.service';
import { TagDto } from '../../services/tag/tag.types';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrl: './side-panel.component.scss',
})
export class SidePanelComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  sidePanel: SidePanel = this.getDefaultSidePanel();
  allTags: TagDto[] = [];
  minDate: Date = new Date();
  maximumDate: Date = new Date(
    new Date().setFullYear(new Date().getFullYear() + 10),
  );

  constructor(
    private ratingService: RatingService,
    private tagService: TagService,
    private likeService: LikeService,
    private fileService: FileService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private toastService: ToastService,
  ) {
    this.getConnectedUser();
  }

  ngOnInit(): void {
    this.minDate.setDate(this.minDate.getDate() + 1);
    // Fetch tags list
    this.tagService
      .get()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (tagsData) => {
          this.allTags = tagsData?.tags;
        },
        error: (e) => {
          this.toastService.error({ summary: 'Could not get tags' });
          this.sidePanel.status = Status.UNKNOWN;
        },
      });
    // Heartbeat answer extension
    EventManager.on('message', (event) => {
      switch (event.data.identifier) {
        case Heartbeat.EXTENSION_HEARTBEAT:
          window.parent.postMessage(
            {
              reason: 'heartbeat',
              identifier: Heartbeat.APP_HEARTBEAT,
              sentAt: Date.now(),
            },
            '*',
          );
          break;
        case Heartbeat.EXTENSION_DOCUMENT:
          this.fillSidePanelWithGivenDocumentData(event.data.docId);
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
  }

  getConnectedUser() {
    const user = this.localStorageService.getItem<PeopleInfo>(USER_INFO_KEY);

    if (user) this.sidePanel.connectedUser = user as any;
    else {
      this.authService
        .getUser()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((user: any) => {
          this.sidePanel.connectedUser = user.user;
          this.localStorageService.setItem<PeopleInfo>(
            USER_INFO_KEY,
            user.user,
          );
        });
    }
  }

  getDefaultSidePanel(): SidePanel {
    this.sidePanel = {
      connectedUser: undefined,
      documentConfidentialities: CONFIDENTIALITIES.map((confidentiality) => ({
        name: confidentiality,
      })),
      documentConfidentiality: '',
      documentContributors: [],
      documentCreator: '',
      documentDescription: '',
      documentId: '',
      documentLabels: [],
      documentLanguage: '',
      documentName: '',
      documentTags: [],
      suggestedTags: [],
      documentType: '',
      documentTypes: DOC_TYPES.map((type) => ({ name: type })),
      documentRating: 0,
      documentRatings: [],
      languages: LANGUAGES.map((language) => ({ name: language })),
      likedByMe: false,
      likesCount: 0,
      selectedExpirationDate: null,
      openDialogs: {
        description: false,
        properties: false,
        rating: false,
        workflow: false,
        flag: false,
      },
      selectedApprovalConfidentiality: { name: '' },
      selectedDocumentConfidentiality: { name: '' },
      selectedDocumentDescription: '',
      selectedDocumentTags: [],
      selectedDocumentType: { name: '' },
      selectedLanguage: { name: '' },
      selectedRating: 0,
      splitButtonItems: [
        {
          label: 'Flag content',
          command: () => {
            this.toggleDialog('flag');
          },
        },
        {
          label: 'Edit description',
          command: () => {
            this.toggleDialog('description');
          },
        },
      ],
      status: Status.UNKNOWN,
      childrenStatus: {
        workflow: Status.UNKNOWN,
      },
      userCurrentRating: 0,
      userIsReaderOrComentator: false,
      userRelatedToWorkflow: false,
      workflowState: Worklow.NONE,
      unflagDocChecked: false,
      reasonToFlag: '',
      fileLocked: false,
    };
    this.getConnectedUser();
    return this.sidePanel;
  }

  fillSidePanelWithGivenDocumentData(documentId: string | null) {
    if (documentId === null) {
      this.sidePanel.status = Status.UNKNOWN;
    } else if (
      this.sidePanel.documentId === '' ||
      this.sidePanel.documentId !== documentId
    ) {
      this.sidePanel = this.getDefaultSidePanel();
      this.sidePanel.status = Status.LOADING;
      this.sidePanel.documentId = documentId;
      this.sidePanel.status = Status.LOADING;
      this.fileService
        .get(documentId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (document) => {
            if (document?.file?.drive_id === null) {
              this.sidePanel.status = Status.MY_DRIVE;
              return;
            }
            /** Fill the document language */
            if (document?.file?.datamart?.language)
              this.sidePanel.selectedLanguage = {
                name: document.file.datamart.language ?? '',
              };
            this.sidePanel.documentLanguage =
              document?.file?.datamart?.language ?? '';
            /** Fill the document type */
            this.sidePanel.documentType =
              document?.file?.datamart?.document_type ?? '';
            this.sidePanel.selectedDocumentType = {
              name: document?.file?.datamart?.document_type ?? '',
            };
            /** Fill the document confidentiality */
            this.sidePanel.documentConfidentiality =
              document?.file?.datamart?.confidentiality ?? '';
            this.sidePanel.documentConfidentiality = this.capitalizeFirstLetter(
              this.sidePanel.documentConfidentiality,
            );
            this.sidePanel.selectedDocumentConfidentiality = {
              name: document?.file?.datamart?.confidentiality ?? '',
            };
            this.sidePanel.selectedDocumentConfidentiality.name =
              this.capitalizeFirstLetter(
                this.sidePanel.selectedDocumentConfidentiality.name,
              );
            /** Fill the document name */
            this.sidePanel.documentName = document?.file?.name ?? '';
            /** Fill the document description */
            this.sidePanel.documentDescription =
              document?.file?.datamart?.description ?? '';
            this.sidePanel.selectedDocumentDescription =
              document?.file?.datamart?.description ?? '';

            /** Fill the document ratings */
            this.sidePanel.documentRatings = [];
            document?.file?.ratings?.forEach((rating) => {
              this.sidePanel.documentRatings.push(rating.rating);
              this.sidePanel.documentRating += rating.rating;
              if (
                rating?.rated_by?.email === this.sidePanel.connectedUser?.email
              ) {
                this.sidePanel.userCurrentRating = rating.rating;
                this.sidePanel.selectedRating = rating.rating;
              }
            });
            if (this.sidePanel.documentRatings.length !== 0) {
              this.sidePanel.documentRating =
                this.sidePanel.documentRating /
                this.sidePanel.documentRatings.length;
            } else {
              this.sidePanel.documentRating = 0;
              this.sidePanel.selectedRating = 0;
              this.sidePanel.userCurrentRating = 0;
            }
            /** Rounds the average rating */
            this.sidePanel.documentRating =
              Math.round(this.sidePanel.documentRating * 100) / 100;

            /** Fill the document likes */
            document?.file?.likes?.forEach((like: any) => {
              this.sidePanel.likesCount++;
              if (
                !this.sidePanel.likedByMe &&
                like?.liked_by?.email === this.sidePanel.connectedUser?.email
              ) {
                this.sidePanel.likedByMe = true;
              }
            });

            /** Fill the document labels */
            this.sidePanel.documentLabels = document?.file?.labels
              ? [...(document?.file?.labels as [])]
              : [];

            /** Fill the reason to flag */
            this.sidePanel.reasonToFlag =
              this.sidePanel.documentLabels.find(
                (label) => label.type === 'flagged',
              )?.reason ?? '';

            /** Fill the split button */
            if (
              this.sidePanel.documentLabels.some(
                (label) => label.type === 'flagged',
              )
            ) {
              this.sidePanel.splitButtonItems[0].label = 'Unflag content';
              this.sidePanel.splitButtonItems[0].command = () => {
                this.unflagDocument();
              };
            }

            /** Fill the document creator */
            this.sidePanel.documentCreator =
              document?.file?.datamart?.created_by?.fullname ?? '';

            /** Fill the document contributors */
            if (document?.file?.datamart?.contributors)
              this.sidePanel.documentContributors = [
                ...document?.file?.datamart?.contributors.map<string>(
                  (c) => c.fullname as string,
                ),
              ];

            /** Fill the document tags */
            this.sidePanel.documentTags =
              document?.file?.tags?.map((t) => ({
                label: t.tag.label as string,
                source: (t as any)?.source,
              })) ?? [];

            this.sidePanel.selectedDocumentTags =
              document?.file?.tags?.map((t) => ({
                label: t.tag.label as string,
                id: t.tag.id,
              })) ?? [];

            /** Fill the user rights */
            this.sidePanel.userIsReaderOrComentator =
              (document.user_permissions as string) === 'reader' ||
              (document.user_permissions as string) === 'commentator';
            if (this.sidePanel.userIsReaderOrComentator) {
              /** Remove last object of the array of 2 elements (Do not use pop since it will not refresh the primeNG component) */
              this.sidePanel.splitButtonItems = this.sidePanel.splitButtonItems.slice(0, 1);
            }

            /** Fill the document workflow state */
            this.updateWorkflow(documentId);
            this.sidePanel.status = Status.FILLED;

            /* Check if the file is locked and remove splitbutton dropdown elements */
            this.sidePanel.fileLocked = document.file.is_locked ?? false;
            if (this.sidePanel.fileLocked) {
              this.sidePanel.splitButtonItems = [];
            }

          },
          error: (e: any) => {
            if (
              e?.error?.statusCode === 400 &&
              e?.error?.message === 'The file is part of a MyDrive.'
            ) {
              this.sidePanel.status = Status.MY_DRIVE;
            } else {
              this.toastService.error({
                summary: 'Could not get file',
              });
              this.sidePanel.status = Status.UNKNOWN;
            }
          },
        });
    }
  }

  updateWorkflow(documentId: string) {
    this.fileService
      .getWorkflow(documentId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        error: (error: any) => {
          this.sidePanel.workflowState = Worklow.NONE;
        },
        next: (response) => {
          //this.workflowState = response.workflow.state;
          switch (response.workflow.state) {
            case 'waiting':
              this.sidePanel.workflowState = Worklow.PENDING;
              break;
            case 'validated':
              this.sidePanel.workflowState = Worklow.APPROVED;
              break;
            case 'rejected':
              this.sidePanel.workflowState = Worklow.REJECTED;
              break;
            default:
              this.sidePanel.workflowState = Worklow.NONE;
              break;
          }

          response.workflow.related_users.forEach((user: any) => {
            if (user.user.email === this.sidePanel.connectedUser?.email)
              this.sidePanel.userRelatedToWorkflow = true;
          });
        },
      });
  }

  toggleDialog(dialog: DialogsKey, state?: boolean) {
    if (state !== undefined) {
      this.sidePanel.openDialogs[dialog] = state;
    } else {
      this.sidePanel.openDialogs[dialog] = !this.sidePanel.openDialogs[dialog];
    }
  }

  async searchTags(event?: any) {
    this.sidePanel.suggestedTags = [];

    if (event.query === '') {
      return this.sidePanel.suggestedTags;
    }
    // Check if the tag name is not already in the list
    const tagExists = this.allTags.filter(
      (tag) => tag.label.toLowerCase() === event.query.toLocaleLowerCase(),
    );

    this.sidePanel.suggestedTags = this.allTags.filter((tag) => {
      return tag.label.toLowerCase().includes(event.query.toLowerCase());
    });
    if (tagExists.length === 0) {
      // Add the tag to the beginning of the list if it doesn't exist without deleting the other tags
      this.sidePanel.suggestedTags = [
        { id: -1, label: event.query },
        ...this.sidePanel.suggestedTags,
      ];
    }

    return this.sidePanel.suggestedTags;
  }

  likeDocument() {
    this.likeService
      .like(this.sidePanel.documentId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.sidePanel.likedByMe = true;
        this.sidePanel.likesCount++;
      });
  }

  dislikeDocument() {
    this.likeService
      .unlike(this.sidePanel.documentId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.sidePanel.likesCount--;
        this.sidePanel.likedByMe = false;
      });
  }

  updateDocumentDescription() {
    this.fileService
      .updateFile(this.sidePanel.documentId, {
        language: this.sidePanel.selectedLanguage,
        //document_type: this.selectedDocType,
        description: this.sidePanel.selectedDocumentDescription,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.sidePanel.documentDescription =
          this.sidePanel.selectedDocumentDescription;
        this.toggleDialog('description', false);
      });
  }

  updateDocumentRating() {
    this.ratingService
      .rateFile({
        file_id: this.sidePanel.documentId,
        rating: this.sidePanel.selectedRating,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.sidePanel.userCurrentRating = this.sidePanel.selectedRating;
        let ratingIsAnAddition = true;
        /** Update response rating */
        response.file.ratings.forEach((rating: any) => {
          if (rating.id === response.rating.id) {
            rating.rating = response.rating.rating;
            ratingIsAnAddition = false;
          }
        });
        /** Add the rating if it's a new one */
        if (ratingIsAnAddition) {
          response.file.ratings.push(response.rating);
        }
        /** Reset ratings before calculation */
        this.sidePanel.documentRatings = [];
        this.sidePanel.documentRating = 0;
        /** Fill the ratings */
        response?.file?.ratings?.forEach((rating: any) => {
          this.sidePanel.documentRatings.push(rating.rating);
          this.sidePanel.documentRating += rating.rating;
        });
        this.sidePanel.documentRating =
          this.sidePanel.documentRating / this.sidePanel.documentRatings.length;
        /** Rounds the average rating */
        this.sidePanel.documentRating =
          Math.round(this.sidePanel.documentRating * 100) / 100;
        this.toggleDialog('rating', false);
      });
  }

  onTagClick(event: Event, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
  }

  flagDocument() {
    this.fileService
      .flagDocument(this.sidePanel.documentId, this.sidePanel.reasonToFlag)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.toggleDialog('flag', false);
          if (!this.sidePanel.documentLabels.find((l) => l.type === 'flagged'))
            this.sidePanel.documentLabels.push({
              type: 'flagged',
              reason: this.sidePanel.reasonToFlag,
              by: {
                email: this.sidePanel.connectedUser?.email,
                created_at: new Date().toISOString(),
              },
            });
          this.sidePanel.splitButtonItems[0].label = 'Unflag content';
          this.sidePanel.splitButtonItems[0].command = () => {
            this.unflagDocument();
          };
        },
        error: (e: any) => {
          this.toastService.error({
            summary: 'Could not flag document',
          });
        },
      });
  }

  unflagDocument() {
    this.fileService
      .unflagDocument(this.sidePanel.documentId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.sidePanel.documentLabels = this.sidePanel.documentLabels.filter(
            (label) => label.type !== 'flagged',
          );
          this.sidePanel.splitButtonItems[0].label = 'Flag content';
          this.sidePanel.reasonToFlag = '';
          this.sidePanel.splitButtonItems[0].command = () => {
            this.toggleDialog('flag');
          };
        },
        error: (e: any) => {
          this.toastService.error({
            summary: 'Could not remove flag from document',
          });
        },
      });
  }

  async updateDocumentProperties() {
    /* Update the document confidentiality property */
    if (this.sidePanel.selectedDocumentConfidentiality.name) {
      this.fileService
        .setConfidentiality(
          this.sidePanel.documentId,
          this.sidePanel.selectedDocumentConfidentiality.name.toLowerCase(),
        )
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          error: (e: any) => {
            this.toastService.error({
              summary: 'Could not set confidentiality.',
            });
          },
        });

      this.sidePanel.documentConfidentiality =
        this.sidePanel.selectedDocumentConfidentiality.name;
    }

    /** Update the document language / document type and description properties */
    let propertiesToUpdate: any = {};
    if (this.sidePanel.selectedLanguage.name) {
      propertiesToUpdate.language = this.sidePanel.selectedLanguage.name;
    }
    if (this.sidePanel.selectedDocumentType.name) {
      propertiesToUpdate.document_type =
        this.sidePanel.selectedDocumentType.name;
    }
    if (this.sidePanel.selectedDocumentDescription) {
      propertiesToUpdate.description =
        this.sidePanel.selectedDocumentDescription;
    }
    if (Object.keys(propertiesToUpdate).length !== 0) {
      const properties$ = this.fileService
        .updateFile(this.sidePanel.documentId, propertiesToUpdate)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          error: (e: any) => {
            this.toastService.error({
              summary: 'Could not update file properties.',
            });
          },
        });

      this.sidePanel.documentLanguage =
        propertiesToUpdate.language ?? this.sidePanel.documentLanguage;
      this.sidePanel.documentType =
        propertiesToUpdate.document_type ?? this.sidePanel.documentType;
      this.sidePanel.documentDescription =
        propertiesToUpdate.description ?? this.sidePanel.documentDescription;
    }

    /** Update the document tags */
    const tagsIds: number[] = [];
    const tagsPromises: Promise<any>[] = [];

    /** Loop over the tags to create the new ones and add all ids in the array */
    this.sidePanel.documentTags = [];
    this.sidePanel.selectedDocumentTags.forEach((tag: any) => {
      if (tag.id !== -1) {
        tagsIds.push(tag.id);
        this.sidePanel.documentTags.push(tag);
      } else {
        if (tag.label !== '') {
          tagsPromises.push(
            lastValueFrom(this.tagService.create(tag.label)).then(
              (response) => {
                tagsIds.push(response.tag.id);
                this.sidePanel.documentTags.push(tag);
              },
            ),
          );
        }
      }
    });
    await Promise.all(tagsPromises);
    const tags$ = this.fileService
      .setTags({ file_id: this.sidePanel.documentId, ids: tagsIds })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        error: (e: any) => {
          this.toastService.error({
            summary: 'Could not set tags',
          });
        },
      });

    this.toggleDialog('properties', false);
  }

  getConfidentialityExplicativeText(): string {
    switch (this.sidePanel.selectedDocumentConfidentiality.name) {
      case 'Public':
        return 'Anyone on the internet with the link of the document can view it.';
      case 'Confidential':
        return 'Only people with explicit access can open & view the document.';
      default:
        return 'Anyone from your company can view & find the document using the search.';
    }
  }

  handleWorkflowAction(action: string) {
    switch (action) {
      case 'approve':
        this.toggleDialog('workflow', true);
        break;
      case 'approved':
        this.sidePanel.childrenStatus['workflow'] = Status.LOADING;
        // Create an expiration date ISO 8601 string
        const expirationDate = new Date(
          this.sidePanel.selectedExpirationDate as Date,
        ).toISOString();

        this.fileService
          .setFileAsApproved(
            this.sidePanel.documentId,
            this.sidePanel.selectedApprovalConfidentiality.name.toLowerCase(),
            (this.sidePanel.selectedExpirationDate) ? expirationDate : null,
          )
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (r) => {
              this.sidePanel.workflowState = Worklow.APPROVED;
              this.sidePanel.documentLabels.push({ type: 'verified' });
              this.toggleDialog('workflow', false);

              if (r.indexationErrors?.length) {
                this.toastService.warn({
                  summary: 'File has been approved',
                  detail: 'File could not be indexed for Ask AI',
                });
              } else {
                this.toastService.success({
                  summary: 'File has been approved',
                  detail: 'File has been indexed for Ask AI',
                });
              }
            },
            error: (e) => {
              this.toastService.error({
                summary: 'Could not approve file',
              });
              this.sidePanel.childrenStatus['workflow'] = Status.UNKNOWN;
            },
            complete: () => {
              this.sidePanel.childrenStatus['workflow'] = Status.FILLED;
            },
          });
        break;
      case 'reject':
        this.fileService
          .setFileAsRejected(this.sidePanel.documentId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (response) => {
              this.sidePanel.workflowState = Worklow.REJECTED;
            },
            error: (e) => {
              this.toastService.error({
                summary: 'Could not reject file',
              });
            },
          });
        break;
      case 'verify':
        this.fileService
          .setFileAsVerified(this.sidePanel.documentId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (response) => {
              this.sidePanel.workflowState = Worklow.PENDING;
              // update workflow to re fetch related users.
              this.updateWorkflow(this.sidePanel.documentId);
            },
            error: (e) => {
              this.toastService.error({
                summary: 'Could not send file verification',
              });
            },
          });
        break;
    }
  }

  formatDate(date: string = ''): string {
    if (!date) {
      return '';
    }
    const [year, month, day] = date.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  }

  unlockFile() {
    this.fileService.unlockFile(this.sidePanel.documentId).subscribe({
      next: () => {
        this.sidePanel.fileLocked = false;
        this.toastService.success({
          summary: 'File has been unlocked',
        });
      },
      error: (e) => {
        this.toastService.error({
          summary: 'Could not unlock file',
        });
      },
    });
  }

}
