import { Chip } from 'app/components/chip/chip.types';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { PrimeFileNode, WorkflowEntity } from 'app/types/documentTypes';
import { FileService } from 'app/services/file/file.service';
import { Filter, Todo } from './todo.types';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { EventManager } from 'app/services/events/events.service';
import { LocalStorageService, USER_INFO_KEY } from 'app/services/local-storage/local-storage.service';
import { PeopleInfo } from 'app/types/peopleTypes';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrl: './todo.component.scss',
  providers: [ConfirmationService],
})
export class TodoComponent implements OnDestroy, OnInit {
  /** READONLY VALUE */
  readonly ALL: string = Filter.ALL;
  readonly DEFAULT_DISPLAY_MIN_ELEMENTS: number = 3;
  readonly EXTENSION_HEARTBEAT: string = 'PRESENCEDOC_EXTENSION_HEARTBEAT';
  readonly TABLES_SHORT_CONTAINER: string = 'tables-short-container';
  readonly TO_APPROVE: string = Filter.TO_APPROVE;
  readonly TO_APPROVE_PARAM: string = 'waiting';
  readonly TO_UPDATE: string = Filter.TO_UPDATE;
  readonly I_REQUESTED: string = Filter.I_REQUESTED;

  /** Class properties */
  currentUser: PeopleInfo | null = null;
  filesToApproveSubscription: Subscription | undefined;
  filesToUpdateSubscription: Subscription | undefined;
  todo: Todo = {
    breadcrumbIcon: { icon: '', routerLink: '' },
    breadcrumbPath: [],
    chips: [],
    dynamicClass: '',
    filesIRequested: [],
    filesToApprove: [],
    filesToUpdate: [],
    filteredOn: Filter.ALL,
    isFooterDisplayed: false,
    workflows: new Map<string, WorkflowEntity>(),
  };
  selectedFiles: PrimeFileNode[] = [];

  constructor(
    private fileService: FileService,
    private gdriveService: GdrivesService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    /** Get current user */
    this.currentUser = this.localStorageService.getItem<PeopleInfo>(USER_INFO_KEY);
    
    if (!this.currentUser) {
      const data = await lastValueFrom(this.authService.getUser());

      this.currentUser = data.user;
      this.localStorageService.setItem<PeopleInfo>(USER_INFO_KEY, this.currentUser);
    }
    /** Fill todo object */
    this.todo = {
      chips: [
        {
          label: 'All',
          icon: 'pi-check',
          isActive: true,
        },
        {
          label: 'To approve',
          icon: '',
          isActive: false,
        },
        {
          label: 'To update',
          icon: '',
          isActive: false,
        },
        {
          label: 'Approval status',
          icon: '',
          isActive: false,
        }
      ],
      dynamicClass: '',
      filesIRequested: [],
      filesToApprove: [],
      filesToUpdate: [],
      breadcrumbIcon: { icon: 'pi pi-home', routerLink: '/todo' },
      breadcrumbPath: [{ label: 'To do' }],
      isFooterDisplayed: false,
      filteredOn: Filter.ALL,
      workflows: new Map<string, WorkflowEntity>(),
    };

    EventManager.on('tableRefresh', () => {
      this.filesToApproveSubscription = this.fileService
        .getWorkflowFiles(this.TO_APPROVE_PARAM)
        .subscribe((response) => {
          this.todo.filesToApprove = [];
          EventManager.emit('clearTableSelection');
          this.todo.isFooterDisplayed = false;
          response.workflows.forEach((workflow) => {
            this.todo.filesToApprove.push(workflow.file as PrimeFileNode);
          });
        });
    });

    /** Get files to approve */
    this.filesToApproveSubscription = this.fileService
      .getWorkflowFiles(this.TO_APPROVE_PARAM)
      .subscribe((response) => {
        response.workflows.forEach((workflow) => {
          this.todo.filesToApprove.push(workflow.file as PrimeFileNode);
        });
      });
    
    /** Get files pending requested by me */
    this.fileService.requestedByMe()
    .subscribe((response) => {
      this.todo.filesIRequested = response.files.map((file) => { return file as PrimeFileNode; });
      response.files.map((file) => {
        if (file?.workflows?.length)
          this.todo.workflows.set(file.id as string, file.workflows[0] as WorkflowEntity);
      });
    });

    /** Get files to update */
    this.filesToUpdateSubscription = this.fileService
      .getFlaggedDocuments()
      .subscribe((response) => {
        response.files.forEach((file) => {
          this.todo.filesToUpdate.push(file as PrimeFileNode);
        });
      });

    /** Listen when files are selected in a table */
    EventManager.on('tableSelectedFiles', () => {
      this.selectedFiles = this.gdriveService.getTableSelectedFile();
      this.todo.isFooterDisplayed = this.selectedFiles.length > 0;
      this.todo.dynamicClass = this.todo.isFooterDisplayed
        ? this.TABLES_SHORT_CONTAINER
        : '';
    });
  }

  ngOnDestroy() {
    this.filesToApproveSubscription?.unsubscribe();
    this.filesToUpdateSubscription?.unsubscribe();
  }

  updateChipAndFilter(chip: Chip): void {
    this.todo?.chips.forEach((chip) => {
      chip.isActive = false;
      chip.icon = '';
    });
    chip.isActive = true;
    chip.icon = 'pi-check';
    this.todo.filteredOn = chip.label as Filter;
    EventManager.emit('clearTableSelection');
  }
}
