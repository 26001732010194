import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';

/* Translation modules */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* PrimeNG Components */
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { StyleClassModule } from 'primeng/styleclass';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RatingModule } from 'primeng/rating';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { FloatLabelModule } from 'primeng/floatlabel';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { StepsModule } from 'primeng/steps';
import { ListboxModule } from 'primeng/listbox';
import { PasswordModule } from 'primeng/password';

/* Components */
import { ChipComponent } from './components/chip/chip.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { NgTableComponent } from './components/ng-table/ng-table.component';
import { SideBarNavigationComponent } from './components/side-bar-navigation/side-bar-navigation.component';
import { InputSwitchModule } from 'primeng/inputswitch';

/* Guards */
/* Pages */
import { TodoComponent } from './pages/todo/todo.component';
import { VerifiedDocsComponent } from './pages/verified-docs/verified-docs.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { FolderContentComponent } from './pages/folder-content/folder-content.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidePanelComponent } from './pages/side-panel/side-panel.component';
import { TrackerComponent } from './components/tracker/tracker.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { LoadingComponent } from './components/loading/loading.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { SetupOnboardingComponent } from './components/setup-onboarding/setup-onboarding.component';

/* Services */

@NgModule({
  declarations: [
    AppComponent,
    TodoComponent,
    VerifiedDocsComponent,
    NotFoundComponent,
    ChipComponent,
    SideBarNavigationComponent,
    NgTableComponent,
    TopBarComponent,
    LoginComponent,
    FolderContentComponent,
    SearchResultsComponent,
    FooterComponent,
    SidePanelComponent,
    TrackerComponent,
    SettingsComponent,
    OnboardingComponent,
    SetupOnboardingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    FormsModule,
    AvatarModule,
    ButtonModule,
    TagModule,
    TableModule,
    ConfirmDialogModule,
    StyleClassModule,
    TreeModule,
    TooltipModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    BreadcrumbModule,
    DropdownModule,
    ProgressBarModule,
    MultiSelectModule,
    DialogModule,
    InputGroupModule,
    InputGroupAddonModule,
    BadgeModule,
    SplitButtonModule,
    RatingModule,
    InputTextareaModule,
    ChipModule,
    ChipsModule,
    CardModule,
    ToastModule,
    CheckboxModule,
    FloatLabelModule,
    LoadingComponent,
    AvatarGroupModule,
    CalendarModule,
    InputSwitchModule,
    OverlayPanelModule,
    StepsModule,
    ListboxModule,
    PasswordModule,
  ],
  providers: [MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
