import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { EventManager } from 'app/services/events/events.service';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { DEFAULT_GOOGLE_AVATAR_URI } from 'app/types/constants';
import {
  File,
  FileEntity,
  PrimeFileNode,
  Rating,
  Tag,
  WorkflowEntity,
} from 'app/types/documentTypes';
import { People, PeopleInfo, WorkflowRelatedUser } from 'app/types/peopleTypes';
import { getFileContributors } from 'app/utils/utils';

@Component({
  selector: 'app-ng-table',
  templateUrl: './ng-table.component.html',
  styleUrl: './ng-table.component.scss',
})
export class NgTableComponent implements OnInit, OnChanges {
  @Input() mode: string = 'documents';
  @Input() documents: Array<PrimeFileNode> = new Array<PrimeFileNode>();
  @Input() peoples: Array<People> = new Array<People>();
  @Input() canSelect: boolean = true;
  @Input() showApprovers: boolean = false;
  @Input() showLastAction: boolean = false;
  @Input() workflows: Map<string, WorkflowEntity> = new Map<string, WorkflowEntity>();

  readonly maximalAvatar: number = 3;
  maximalMarkPossible: number = 5;
  selectedFiles: Array<PrimeFileNode> = new Array<PrimeFileNode>();
  readonly default_avatar_uri: string = DEFAULT_GOOGLE_AVATAR_URI;
  //allTags: Map<number, string> = new Map<number, string>();

  constructor(private gdriveService: GdrivesService) {}

  async ngOnChanges() {
    this.documents.forEach((file) => {
      file.averageRating = this.getRatingAverage(file);
    });
  }

  async ngOnInit() {
    EventManager.on('clearTableSelection', () => {
      this.selectedFiles = new Array<PrimeFileNode>();
      this.sendSelectedFiles();
    });
  }

  async sendSelectedFiles() {
    this.documents?.forEach((file: PrimeFileNode) => {
      // Check if the file is selected
      if (
        this.selectedFiles.find(
          (selectedFile: PrimeFileNode) => selectedFile.id === file.id,
        )
      ) {
        file.selectedRow = 'selected-row';
      } else {
        file.selectedRow = '';
      }
    });
    this.gdriveService.setTableSelectedFile(this.selectedFiles);
    // Generate a custom event to notify the pages component that the selected files have changed
    EventManager.emit('selectedFilesChanged', this.selectedFiles);
  }

  getRatingAverage(file: File): number {
    let sum: number = 0;

    file?.ratings?.forEach((rating: Rating) => {
      sum += rating.rating;
    });
    return file?.ratings?.length ? sum / file?.ratings.length : 0;
  }

  getTooltipTags(file: File): string {
    let tags: string = '';

    file?.tags?.forEach((tag: Tag) => {
      tags += tag?.tag?.label + ', ';
    });
    return tags.slice(0, -2);
  }

  getAvatarImage(contrib: PeopleInfo): string {
    return contrib?.photo_uri ?? this.default_avatar_uri;
  }

  getContributorName(contrib: PeopleInfo): string {
    return contrib?.fullname ?? contrib?.name ?? contrib?.email ?? 'Unknown';
  }

  getContributorEmail(contrib: PeopleInfo): string {
    return contrib?.email ?? 'Unknown';
  }

  getContributorTooltip(contrib: PeopleInfo): string {
    const name = this.getContributorName(contrib);

    if (contrib?.title) {
      return `${name}\n\n${contrib.title}`;
    }

    return name;
  }

  getContributorsTooltip(contributors: PeopleInfo[]): string {
    return contributors
      .slice(this.maximalAvatar, contributors.length)
      .map((pi) => this.getContributorName(pi))
      .join('\n');
  }

  getContributors(
    file: FileEntity,
  ): { contribs: PeopleInfo[]; count: number; names: string } | undefined {
    const people = getFileContributors(file);

    return people;
  }

  getWorkflowContributors(user: WorkflowRelatedUser): string {
    let name = this.getContributorTooltip(user.people);

    if (name.toLowerCase() === 'unknown') {
      name = user.email;
    }
    return name;
  }
}
