import { EventManager } from 'app/services/events/events.service';
import { GoogleMimeTypes } from './constants';
import { PrimeFileNode } from './documentTypes';

export interface AddEntityOptions {
  /** The entities to add */
  entities: PrimeFileNode[];

  /** Emit an event with the given string with detail = this class. */
  emitEvent?: string;
}

export class EntityDB {
  /** Flat map of all folder entities by id */
  private folderFlatMap: { [key: string]: PrimeFileNode } = {};

  private tree: { [key: string]: PrimeFileNode } = {};

  add(options: AddEntityOptions) {
    /** Copy to avoid references elsewhere. */
    const entities = structuredClone(options.entities);

    /** Add each entity to the flat map */
    for (const entity of entities) {
      this.folderFlatMap[entity.id] = entity;

      /** If the entity had a parent, link it. */
      if (entity?.parent) {
        const parent = this.folderFlatMap[entity.parent.id];
        if (!parent.children) parent.children = [entity];
        else if (parent.children.findIndex((f) => f.id === entity.id) === -1)
          parent.children.push(entity);
      }

      /** If the entity is a drive, place it on top. */
      if (entity.mime_type === GoogleMimeTypes.drive) {
        this.tree[entity.id] = entity;
      }
    }
    if (options.emitEvent)
      EventManager.emit(options.emitEvent, { db: this, entities: entities });
  }

  get(id: string): PrimeFileNode | undefined {
    return this.folderFlatMap[id];
  }

  getTree(): { [key: string]: PrimeFileNode } {
    return this.tree;
  }

  path(node: PrimeFileNode | PrimeFileNode): string[] {
    const path: string[] = [];
    let currentNode: PrimeFileNode = node as PrimeFileNode;

    while (currentNode) {
      path.unshift(currentNode.id);
      currentNode = currentNode.parent as PrimeFileNode;
    }

    return path;
  }

  count(selector?: (item: PrimeFileNode) => boolean): number {
    if (!selector) {
      return Object.keys(this.folderFlatMap).length;
    }
    return Object.entries(this.folderFlatMap).filter(([_, v]) => selector(v))
      .length;
  }
}
