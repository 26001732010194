import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientResponse, CreateClientOptions } from './client.types';
import { TrinityService } from 'app/services/trinity/trinity.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly endpoint: string = '/client';
  constructor(private trinityService: TrinityService) {}

  getClient(): Observable<ClientResponse> {
    const res = this.trinityService.get<ClientResponse>(this.endpoint, {
      authorized: true,
    });

    return res;
  }

  createClient(options: CreateClientOptions): Observable<ClientResponse> {
    const res = this.trinityService.post<ClientResponse>(this.endpoint, {
      body: {
        name: options.name,
      },
      authorized: true,
    });

    return res;
  }

  modifyClient(options: CreateClientOptions): Observable<ClientResponse> {
    const res = this.trinityService.put<ClientResponse>(this.endpoint, {
      body: options,
      authorized: true,
    });

    return res;
  }

  deleteClient(): Observable<ClientResponse> {
    const res = this.trinityService.delete<ClientResponse>(this.endpoint, {
      authorized: true,
    });

    return res;
  }
}
