<div class="footer">
  <div class="footer-text">{{ selectedFiles.length }} files selected</div>
  <div class="footer-buttons">
    @if (AI) {
      <p-button severity="secondary" label="Ask AI" size="small"></p-button>
    }
    @if (properties) {
      <p-button
        severity="secondary"
        label="Apply properties"
        size="small"
        (mousedown)="toggleDialogState('updateProperties', true)"
      ></p-button>
    }
    @if (reject) {
      <p-button
        severity="secondary"
        label="Reject selected files"
        size="small"
        (mousedown)="toggleDialogState('rejectDocuments', true)"
      ></p-button>
    }
    @if (verification) {
      <p-button
        severity="primary"
        label="Verify selected files"
        size="small"
        (mousedown)="toggleDialogState('verifyDocuments', true)"
      ></p-button>
    }
    @if (request) {
      <p-button
        severity="primary"
        label="Request verification"
        size="small"
        (mousedown)="toggleDialogState('requestVerification', true)"
        [disabled]="!isDriveScanned"
        pTooltip=" {{
          isDriveScanned
            ? 'Request verification'
            : 'Ask a manager of this Shared drive to connect to Overlayer to be able to use “Verification” process'
        }}"
      ></p-button>
    }
  </div>
</div>

<p-dialog
  header="Apply confidentiality level & verify documents"
  [modal]="true"
  [draggable]="false"
  [(visible)]="dialogModalStates.verifyDocuments.opened"
  position="center"
  [style]="{ width: '50%' }"
>
  <div class="approval-dropdown">
    <p-dropdown
      [options]="confidentialities"
      [(ngModel)]="selectedConfidentiality"
      optionLabel="name"
      placeholder="Confidentiality"
      class="dropdown-width"
    />
  </div>
  <div>
    You're about to approve {{ selectedFiles.length }} approbation requests.
    Please set the confidentiality level before being able to obtain the label
    "Verified".
  </div>
  <div class="expiration-date">
    <p-calendar
      [(ngModel)]="selectedExpirationDate"
      [minDate]="minDate"
      [maxDate]="maximumDate"
      dateFormat="dd / mm / yy"
      [readonlyInput]="true"
      inputId="expiration_date"
      placeholder="Expiration date (optional)"
    />
  </div>
  <div class="dialog-actions">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="toggleDialogState('verifyDocuments', false)"
    />
    <p-button
      label="Verify {{ selectedFiles.length }} files"
      (onClick)="verifyDocuments()"
      [disabled]="!selectedConfidentiality.name"
    />
  </div>
</p-dialog>

<p-dialog
  header="Request verification"
  [modal]="true"
  [(visible)]="dialogModalStates.requestVerification.opened"
  position="center"
>
  <div>
    You're about to request verification for {{ selectedFiles.length }} files.
  </div>
  <div class="dialog-actions">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="toggleDialogState('requestVerification', false)"
    />
    <p-button
      label="Request verification on {{ selectedFiles.length }} files"
      (mousedown)="requestVerification()"
    />
  </div>
</p-dialog>

<p-dialog
  header="Reject Documents"
  [modal]="true"
  [(visible)]="dialogModalStates.rejectDocuments.opened"
  position="center"
>
  <div>
    You're about to reject {{ selectedFiles.length }} verification requests
  </div>
  <div class="dialog-actions">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="toggleDialogState('rejectDocuments', false)"
    />
    <p-button
      label="Reject {{ selectedFiles.length }} requests "
      (mousedown)="rejectDocuments()"
    />
  </div>
</p-dialog>

<p-dialog
  header="Apply your edits"
  [modal]="true"
  [(visible)]="dialogModalStates.updateProperties.opened"
  [style]="{ width: '30%' }"
>
  <div class="apply-properties">
    <p-autoComplete
      [(ngModel)]="selectedTags"
      [suggestions]="suggestedTags"
      optionLabel="label"
      [multiple]="true"
      (completeMethod)="searchTags($event)"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
      placeholder="Select tags"
    >
      <ng-template let-tag pTemplate="item">
        @if (tag.id !== -1) {
          <div class="tag-selector">
            <i class="pi pi-tags"></i>
            <span>{{ tag.label }}</span>
          </div>
        } @else {
          <div class="tag-selector blue">
            <i class="pi pi-plus-circle"></i>
            <span>{{ tag.label }}</span>
          </div>
        }
      </ng-template>
    </p-autoComplete>
    <p-dropdown
      [options]="confidentialities"
      [(ngModel)]="selectedConfidentiality"
      optionLabel="name"
      placeholder="Confidentiality"
      class="dropdown-width"
    />
    <p-dropdown
      [options]="docTypes"
      [(ngModel)]="selectedDocType"
      optionLabel="name"
      placeholder="Document type"
      class="dropdown-width"
    />
    <p-dropdown
      [options]="languages"
      [(ngModel)]="selectedLanguage"
      optionLabel="name"
      placeholder="Language"
      class="dropdown-width"
    />
    <diV class="properties-buttons">
      <p-button
        label="Cancel"
        severity="secondary"
        (mousedown)="toggleDialogState('updateProperties', false)"
      />
      <p-button
        label="Apply to the {{ selectedFiles.length }} files selected"
        (mousedown)="applyProperties()"
      />
    </diV>
  </div>
</p-dialog>

<p-dialog
  header="Unable to apply Properties"
  [modal]="true"
  [(visible)]="dialogModalStates.disableEditProperties.opened"
  position="center"
>
  <div class="info-text">
    Your rights regarding the following {{ entitiesNotUpdated.length }} files
    prevent you from applying properties to them:
  </div>
  <p-card class="file-card" *ngFor="let entity of entitiesNotUpdated">
    <img src="{{ entity.id.icon_link }}" alt="" class="file-icon" />
    <span>{{ entity.id.name }}</span>
    <span class="failed-icon-info">
      <i class="pi pi-info-circle" pTooltip="{{ entity.reason }}"></i>
    </span>
  </p-card>
  <div class="dialog-one-action">
    <p-button label="I understand" (mousedown)="requestEditorAccess()" />
  </div>
</p-dialog>

<p-dialog
  header="Ask to be an editor"
  [modal]="true"
  [(visible)]="askToBeEditor"
  [style]="{ width: '40%' }"
>
  <textarea
    rows="5"
    pInputTextarea
    [(ngModel)]="reasonToBeEditor"
    class="description-textarea"
  >
  </textarea>
  <div class="dialog-actions">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="closeReasonToBeEditorDialog()"
    />
    <p-button
      label="Save"
      (mousedown)="sendReasonToBeEditor()"
      [disabled]="reasonToBeEditor === ''"
    />
  </div>
</p-dialog>

<p-dialog
  header="Unable to Request verification"
  [modal]="true"
  [(visible)]="dialogModalStates.requestVerificationErrors.opened"
  [style]="{ width: '40%' }"
  position="center"
>
  <div class="info-text">
    We were not able to request verification for the following
    {{ unrequestedFiles.length }} files:
  </div>
  <p-card class="file-card" *ngFor="let file of unrequestedFiles">
    <img src="{{ file.id.icon_link }}" alt="" class="file-icon" />
    <span>{{ file.id.name }}</span>
    <span class="failed-icon-info">
      <i class="pi pi-info-circle" pTooltip="{{ file.reason }}"></i>
    </span>
  </p-card>
  <div class="dialog-one-action">
    <p-button
      label="I understand"
      (mousedown)="dismissVerificationErrorsModal()"
    />
  </div>
</p-dialog>
