import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
})
export class ChipComponent {
  @Input() icon: string = '';
  @Input() isActive: boolean = false;
  @Input() label: string = '';

  constructor() {}
}
