import { MenuItem } from 'primeng/api';
import { PeopleInfo } from 'app/types/peopleTypes';
import { Label } from 'app/types/documentTypes';

export enum Heartbeat {
  APP_HEARTBEAT = 'PRESENCEDOC_APP_HEARTBEAT',
  EXTENSION_HEARTBEAT = 'PRESENCEDOC_EXTENSION_HEARTBEAT',
  EXTENSION_DOCUMENT = 'PRESENCEDOC_EXTENSION_DOCUMENT',
}

export enum Status {
  FILLED = 'FILLED',
  MY_DRIVE = 'MY_DRIVE',
  LOADING = 'LOADING',
  UNKNOWN = 'UNKNOWN',
}

export enum Worklow {
  APPROVED = 'APPROVED',
  NONE = 'NONE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export interface Dialogs {
  description: boolean;
  properties: boolean;
  rating: boolean;
  workflow: boolean;
  flag: boolean;
}

export type DialogsKey = keyof Dialogs;

export interface SidePanel {
  connectedUser?: PeopleInfo;
  documentConfidentialities: { name: string }[];
  documentConfidentiality: string;
  documentContributors: string[];
  documentCreator: string;
  documentDescription: string;
  documentId: string;
  documentLabels: any[];
  documentLanguage: string;
  documentName: string;
  documentTags: { label: string; source: string }[];
  suggestedTags: any[];
  documentType: string;
  documentTypes: { name: string }[];
  documentRating: number;
  documentRatings: number[];
  languages: { name: string }[];
  likedByMe: boolean;
  likesCount: number;
  selectedExpirationDate: Date | null;
  openDialogs: Dialogs;
  selectedApprovalConfidentiality: { name: string };
  selectedDocumentConfidentiality: { name: string };
  selectedDocumentDescription: string;
  selectedDocumentTags: any[];
  selectedDocumentType: { name: string };
  selectedLanguage: { name: string };
  selectedRating: number;
  splitButtonItems: MenuItem[];
  status: Status;
  childrenStatus: { [key: string]: Status };
  userCurrentRating: number;
  userIsReaderOrComentator: boolean;
  userRelatedToWorkflow: boolean;
  workflowState: Worklow;
  unflagDocChecked: boolean;
  reasonToFlag: string;
  fileLocked: boolean;
}
