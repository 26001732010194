@if (isActive) {
  <!-- Treat the case when the chip is set as active -->
  <div class="chip-container chip-active">
    @if (icon !== '') {
      <i class="pi {{ icon }}"></i>
    }
    <span>{{ label }}</span>
  </div>
} @else {
  <!-- Treat the case for a classic chip -->
  <div class="chip-container">
    @if (icon !== '') {
      <i class="pi {{ icon }}"></i>
    }
    <span>{{ label }}</span>
  </div>
}
