import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { getLoginURI } from 'app/services/api-settings';
import {
  AuthorizeUri as AuthorizeUrl,
  JwtTokenPair,
  Role
} from 'app/types/userTypes';
import {
  AUTH_INFO,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import {
  AuthInfo,
  GetAuthorizeUriOptions,
  UserResponse,
  VerifyRejection,
  VerifyRejectionReason,
  VerifyResponse,
} from './auth.types';
import { TrinityService } from '../trinity/trinity.service';
import { LocalStorageService } from 'app/services/local-storage/local-storage.service';

/** Five minutes left */
const REFRESH_DELAY_SECONDS = 5 * 60;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  /** The automatic refresh token manager. */
  private static automaticRefreshTokenManager?: NodeJS.Timeout | null = null;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  readonly redirectUri: string = getLoginURI();

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private trinityService: TrinityService,
  ) {
    if (AuthService.automaticRefreshTokenManager === null) {
      console.debug('Starting automatic refresh token manager...');
      this.__refreshTokenManager();
      AuthService.automaticRefreshTokenManager = setInterval(async () => {
        await this.__refreshTokenManager();

        /** Start this every minute. */
      }, 60 * 1000);
    }
  }

  async __refreshTokenManager() {
    const auth = this.localStorageService.getItem<AuthInfo>(AUTH_INFO);
    if (!auth) {
      return;
    }
    console.debug('Checking for access token expiration...');
    if ((auth.expiry_date - REFRESH_DELAY_SECONDS) * 1000 < Date.now()) {
      this.refreshAccessToken()
        .then(() => {
          console.debug('Access token has been refreshed.');
        })
        .catch(() => {
          console.error('Failed to refresh access token.');
        });
    } else {
      console.debug('Access token is still valid.');
    }
  }

  getAuthorizeUrl(options: GetAuthorizeUriOptions): Observable<AuthorizeUrl> {
    return this.trinityService.get<AuthorizeUrl>('/auth/authorize', {
      params: {
        role: options.role ?? Role.CLIENT,
        redirect_uri: options.redirectUri ?? this.redirectUri,
        consent: options.forceConsent ?? false,
        state: options.state ?? '',
      },
    });
  }

  getAccessToken(code: string): Promise<void> {
    const res = this.trinityService.post<JwtTokenPair>(
      `/auth/authorize/${encodeURIComponent(code)}`,
      {authorized: true},
    );

    return new Promise((resolve, reject) => {
      res.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (pair) => {
          const authInfo: AuthInfo = {
            expiry_date: pair.expiry_date,
          }
          this.localStorageService.setItem<AuthInfo>(AUTH_INFO, authInfo);
        },
        complete: () => {
          resolve();
        },
        error: (e) => {
          let reason: VerifyRejectionReason;

          if (
            e?.status === 400 &&
            e?.error?.message.includes('refresh token')
          ) {
            reason = VerifyRejectionReason.GOOGLE_REFRESH_TOKEN_EXPIRED;
          } else if (e?.status === 403) {
            reason = VerifyRejectionReason.GOOGLE_REFRESH_TOKEN_EXPIRED;
          } else {
            reason = VerifyRejectionReason.LOCAL_CREDENTIALS_EXPIRED;
          }

          reject({
            reason: reason,
          } as VerifyRejection);
        },
      });
    });
  }

  verify() {
    return this.trinityService.post<VerifyResponse>('/auth/verify', {
      authorized: true,
    });
  }

  getUser(): Observable<UserResponse> {
    const res = this.trinityService.get<UserResponse>('/user', {
      authorized: true,
    });

    res.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (user) => {
        this.localStorageService.setItem(USER_INFO_KEY, user.user);
      },
      error: (err) => {
        console.error(err);
      },
    });

    return res;
  }

  refreshAccessToken(): Promise<AuthInfo> {
    console.debug('Refreshing access token...');
    const auth = this.localStorageService.getItem<AuthInfo>(AUTH_INFO);

    if (!auth) {
      console.debug('Attempting to refresh token without being connected.');
      return new Promise((resolve, reject) => {
        reject({
          reason: VerifyRejectionReason.LOCAL_CREDENTIALS_EMPTY,
        } as VerifyRejection);
      });
    }

    const res = this.trinityService.post<AuthInfo>('/auth/refresh', {
      authorized: true,
    });

    return new Promise((resolve, reject) => {
      res.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (data) => {
          this.localStorageService.mergeItem(AUTH_INFO, data);
          return resolve(data);
        },
        error: (e) => {
          let reason: VerifyRejectionReason;

          if (e?.status === 403) {
            reason = VerifyRejectionReason.GOOGLE_REFRESH_TOKEN_EXPIRED;
          } else {
            reason = VerifyRejectionReason.LOCAL_CREDENTIALS_EXPIRED;
          }

          reject({
            reason: reason,
          } as VerifyRejection);
        },
      });
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /* Application functions */
  logout() {
    this.localStorageService.deleteItem(AUTH_INFO);
    this.localStorageService.deleteItem(USER_INFO_KEY);
    this.router.navigate(['/login']);
  }

  async isUserAuthenticated(): Promise<void> {
    // Get the auth token from local storage
    const auth = this.localStorageService.getItem<AuthInfo>(AUTH_INFO);

    // Check if auth token exists
    if (!auth) {
      return new Promise((resolve, reject) => {
        reject({
          reason: VerifyRejectionReason.LOCAL_CREDENTIALS_EMPTY,
        } as VerifyRejection);
      });
    }

    return new Promise((resolve, reject) => {
      this.verify()
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          complete: () => {
            resolve();
          },
          error: (e) => {
            let reason: VerifyRejectionReason;

            if (e?.status === 403) {
              reason = VerifyRejectionReason.GOOGLE_REFRESH_TOKEN_EXPIRED;
            } else {
              reason = VerifyRejectionReason.LOCAL_CREDENTIALS_EXPIRED;
            }

            reject({
              reason: reason,
            } as VerifyRejection);
          },
        });
    });
  }
}
