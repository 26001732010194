<div class="top-bar">
  <div class="row-icon-title">
    <img
      src="assets/logo/pdocs.svg"
      alt="presencedoc logo"
      class="top-bar-logo"
    />
    <h1>Overlayer</h1>
  </div>
  <div class="search-and-avatar">
    <p-autoComplete
      #autocomplete
      [(ngModel)]="selectedItem"
      [suggestions]="filteredSearch"
      (completeMethod)="filterSearch($event)"
      (onKeyUp)="displaySearchResult($event)"
      (onSelect)="displayAllResults()"      
      (onShow)="hideOverlay()"
      placeholder="Search"
      field="name"
    >
      <ng-template let-fileorpeople pTemplate="item">
        @if (!fileorpeople?.people?.email && !fileorpeople?.label) {
          <div
            class="search-result-inline"
            (mousedown)="openFileToSidePanel(fileorpeople)"
          >
            <img
              src="{{ fileorpeople.icon_link }}"
              alt="avatar"
              class="file-icon"
            />
            <div class="ellipsis-name" pTooltip="{{ fileorpeople.name }}">
              {{ fileorpeople.name }}
            </div>

            <div
              class="column ellipsis"
              pTooltip="{{ getTooltipTags(fileorpeople) }}"
            >
              <span>{{ getTooltipTags(fileorpeople) }}</span>
            </div>
            <div class="label-cell column">
              @for (label of fileorpeople.labels; track label) {
                @if (label.type.toLowerCase() === 'verified') {
                  <p-tag value="{{ label.type }}" class="success"></p-tag>
                }
                @if (label.type === 'flagged') {
                  <p-tag value="{{ label.type }}" class="warning"></p-tag>
                }
              }
            </div>
            <div class="column">
              {{ fileorpeople.last_modified | date: 'dd/MM/yyyy' }}
            </div>
            @if (!fileorpeople?.ratings?.length) {
              <div class="column">
                <i class="pi pi-star-fill"></i>
                <span>No ratings</span>
              </div>
            } @else {
              <div class="column">
                <i class="pi pi-star-fill golden-star"></i>
                <span
                  >{{ getRatingAverage(fileorpeople).toFixed(1) }}/{{ 5 }}</span
                >
                <span>&nbsp;({{ fileorpeople?.ratings?.length }})</span>
              </div>
            }
          </div>
        } @else if (fileorpeople?.label) {
          <div class="search-result-inline">
            @if (fileorpeople.search) {
              <div class="display-all">
                <div>{{ fileorpeople.label }}</div>
              </div>
            } @else {
              <div class="no-result">
                <div>{{fileorpeople.label}}</div>
              </div>
            }
          </div>
        } @else {
          <div
            class="search-result-inline"
            (mousedown)="openGoogleContacts(fileorpeople)"
          >
            <div class="column search-result-inline">
              <p-avatar
                image="{{
                  fileorpeople.people.photo_uri ?? default_avatar_uri
                }}"
                styleClass="mr-2"
                size="normal"
                shape="circle"
                class="result-icon"
              ></p-avatar>
              <div>
                {{ fileorpeople.people.fullname ?? fileorpeople.people.email }}
              </div>
            </div>
            <div class="column contrib-count">
              <div>
                {{ fileorpeople.count }}
                contributions
              </div>
            </div>
          </div>
        }
      </ng-template>
    </p-autoComplete>
    <p-avatar
      image="{{ avatarUrl }}"
      styleClass="mr-2"
      size="normal"
      shape="circle"
      class="top-bar-avatar"
    ></p-avatar>
  </div>
</div>
