import { User } from 'app/types/userTypes';

export interface GetAuthorizeUriOptions {
  /** The redirect URI to use. */
  redirectUri?: string;
  /** Whether to force the user to consent to the authorization. */
  forceConsent?: boolean;
  /** The role of the user. */
  role?: string;
  /** State of the auth. */
  state?: string;
}

export interface UserResponse {
  user: User;
}

export interface GetAuthorizeStateOptions {
  redirectPath?: string | null;
}

export interface VerifyResponse {
  message: string;
}

export enum VerifyRejectionReason {
  GOOGLE_REFRESH_TOKEN_EXPIRED = 'google_refresh_token_expired',
  LOCAL_CREDENTIALS_EMPTY = 'local_credentials_empty',
  LOCAL_CREDENTIALS_EXPIRED = 'local_credentials_expired',
}

export interface VerifyRejection {
  reason: VerifyRejectionReason;
}

export interface  AuthInfo {
  expiry_date: number;
}
