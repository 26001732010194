import {
  AuthorizedGoogleDrive,
  DriveEntity,
  FileEntity,
  GoogleDrive,
} from 'app/types/documentTypes';
import { PeopleInfo } from 'app/types/peopleTypes';

export interface GetUserDrivesResponse {
  /** The drives that the user has access to. */
  drives: GoogleDrive[];

  /** The next page token. */
  next_page_token?: string;
}

export interface GetUserAuthorizedDrivesResponse {
  /** The drives that the user has access to. */
  drives: AuthorizedGoogleDrive[];

  /** The next page token. */
  next_page_token?: string;
}

export interface GetDriveFilesResponse {
  /** The files in the drive. */
  files: FileEntity[];

  /** The next page token. */
  next_page_token?: string;
}

export enum FileFlagType {
  VERIFIED = 'verified',
  POPULAR = 'popular',
  FLAGGED = 'flagged',
}

export interface GetUserDrivesOptions {
  /** The max number of drives to query per request. */
  limit: number;

  /** The next page token. */
  next_page_token?: string;

  /** Whether to include domain wide drives. */
  include_domain_wide?: boolean;
}

export interface DriveEntityResponse {
  /** The drive entity. */
  drives: DriveEntity[];
}

export interface SearchFilesOptions {
  /** The search query. */
  search: string;

  /** The drive id to search in. */
  driveid?: string;

  /** The max number of files to query per request. */
  limit?: number;

  /** The next page token. */
  next_page_token?: string;
}

export interface ContributionsResponse {
  /** The contributions. */
  contributions: Contribution[];

  /** The next page token. */
  next_page_token?: string;
}

export interface Contribution {
  /** People informations */
  people: PeopleInfo;

  /** Number of contributions of the user */
  count: number;
}
